import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Commun/Banner"
import LayoutList from "../components/Commun/LayoutContent/LayoutList"
import FilterButton from "../components/Commun/LayoutContent/FilterButton"
import IconList from "../components/Commun/LayoutContent/IconList"
import OffreCTA from "../components/Commun/LayoutContent/OffreCTA"
import Social from "../components/Commun/LayoutContent/Social"
import CoachingList from "../components/Commun/CoachingList"
import Temoignages from "../components/Commun/Temoignages"
import Contact from "../components/Commun/Contact"

const Ebooks = ({ data }) => {
  const prismicData = getPrismicData(data)

  const [gender, setGender] = useState("")

  const handleClick = value => {
    value === gender ? setGender("") : setGender(value)
  }

  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO
        title="E-book Coaching Sportif"
        description="Découvrez mes e-books complets pour homme et pour femme selon vos objectifs : prise de masse / muscle, perte de poids, tonification, bien-être, etc. "
      />
      <Banner
        title={prismicData.banner.titre}
        imageBanner={prismicData.banner.image}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
      />
      <LayoutList
        items={prismicData.listeEbooks.items}
        itemSelected={gender}
        prefix="/e-books/"
        CardHoverContent="En savoir plus"
      >
        <FilterButton
          items={prismicData.listeEbooks.items}
          itemSelected={gender}
          handleClick={handleClick}
          buttonTextPrefix="E-book"
        />
        <IconList
          icon1={data.clipboard.childImageSharp.fixed}
          icon2={data.timer.childImageSharp.fixed}
          icon3={data.weight.childImageSharp.fixed}
        />
        <OffreCTA
          titre="Mes Coachings"
          text="Découvrez toutes mes prestations de coaching sportif personnalisé pour homme et pour femme à Nantes et à distance"
          buttonText="En savoir plus"
          buttonUrl="/coaching-sportif-personnalise"
        />
        <Social />
      </LayoutList>
      <CoachingList
        items={prismicData.coaching.items}
        buttonText="Voir mes coachings"
        buttonUrl="/coaching-sportif-personnalise"
        colorSection="ternary"
        padding="50px 0"
        imageEntrainement={data.coachingListEntrainement.childImageSharp.fixed}
        imageNutrition={data.coachingListNutrition.childImageSharp.fixed}
        imageSuivi={data.coachingListSuivi.childImageSharp.fixed}
      />
      {/* <Temoignages items={prismicData.temoignage.items} /> */}
      <Contact />
    </Layout>
  )
}

export default Ebooks

export const query = graphql`
  query {
    prismicEbookBodyBanniere {
      primary {
        titre {
          text
        }
        image {
          fluid(maxWidth: 3000) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    allPrismicEbookDetail {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicEbookDetailBodyContenuPrincipal {
                primary {
                  contenu {
                    text
                  }
                  titre {
                    text
                  }
                  categorie {
                    text
                  }
                  introduction {
                    text
                  }
                  photo {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  statut
                  prix {
                    text
                  }
                  promotion
                  promotion_prix {
                    text
                  }
                  label_promotion {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicAccueilBodyCoaching {
      items {
        contenu {
          html
          text
        }
        titre {
          html
          text
        }
      }
    }
    prismicEbookBodyTemoignages {
      items {
        contenu {
          html
          text
        }
        nom {
          html
          text
        }
        photo {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    clipboard: file(relativePath: { eq: "icones/clipboard-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    timer: file(relativePath: { eq: "icones/timer-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    weight: file(relativePath: { eq: "icones/weight-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListEntrainement: file(
      relativePath: { eq: "programme-entrainement.jpeg" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListNutrition: file(relativePath: { eq: "nutrition.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListSuivi: file(relativePath: { eq: "suivi-perso.jpeg" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicEbookBodyBanniere.primary.titre.text,
      image: data.prismicEbookBodyBanniere.primary.image.fluid,
    },
    listeEbooks: {
      items: data.allPrismicEbookDetail.edges,
    },
    coaching: {
      items: data.prismicAccueilBodyCoaching.items,
    },
    // temoignage: {
    //   items: data.prismicEbookBodyTemoignages.items,
    // },
  }
}
